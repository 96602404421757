import { ChartConfiguration } from 'chart.js';
import { OptionModeInterface } from '../interfaces/optionMode-inteface';
import { OptionInterface } from '../models/option.interface';
import { first } from '../utils/collection.utils';

export const PRELOAD_MODULES = true;

export const PAGE_ELEMENTS = 10;

export const DATA_TITLE = 'title';

export const DATA_BACK_LINK = 'backLink';

export const DAY_HOURS = 24;

export const DAY_MILLIS = 86400000;

export const MIN_VALID_MILLIS = 86340000;

export const SHORT_DAY_MIN_VALID_MILLIS = 82740000;

export const SECTION_START = 'start';

export const SECTION_ANALYSIS = 'analysis';

export const SECTION_STARTUP = 'startup';

export const SECTION_END = 'finish';

export const STATUS_CLOSED = 'closed';

export const PROJECT_FOLDER = 'projects';

export const SUPPLIERS_FOLDER = 'suppliers';

export const MACHINE_TYPE_OPTIONS = ['LATHE', 'CENTER', 'MULTI', 'SPECIAL'];

export const LUBRICANT_OPTIONS = ['OIL', 'MQL', 'EMULSION', 'DRY'];

export const GLOBAL_DOCUMENTS_FOLDER = 'documentos globales';

export const GLOBAL_ACTIONS_FOLDER = 'Acciones globales';

export const INTERNAL_ACTIONS_FOLDER = 'Acciones internas';

export const PROJECT_END_INVOICED = 'INVOICED';

export const PROJECT_END_RECURRENT = 'RECURRENT';

export const PROJECT_END_SUNK = 'SUNK';

export const PROJECT_END_STATUS_OPTIONS = [PROJECT_END_INVOICED, PROJECT_END_RECURRENT, PROJECT_END_SUNK];

export const OFFER_DOCUMENTS_FOLDER = 'ofertas';

export const ORDER_DOCUMENTS_FOLDER = 'pedidos';

export const INVOICE_DOCUMENTS_FOLDER = 'facturas';

export const DELIVERY_NOTE_DOCUMENTS_FOLDER = 'albaranes';

export const ROI_COST_DECIMALS_LONG = 4;

export const ROI_COST_DECIMALS_SHORT = 2;

export const ROI_TIME_DECIMALS_LONG = 3;

export const ROI_TIME_DECIMALS_SHORT = 2;

export const ROUGHNESS_TYPE_OPTIONS = ['RA', 'RZ', 'RT'];

export const HOLE_TYPE_OPTIONS = ['BLIND', 'THROUGH'];

export const LOCALE_ES = 'es';

export const LOCALE_EN = 'en';

export const ERP_CONNECTION_ERROR_CODE = 503;

export const COMMERCIAL_ACTION_PRICE_PER_HOUR = 100;

export const DROPBOX_CONTENT_API_URL = 'https://content.dropboxapi.com/2';

export const DROPBOX_API_URL = 'https://api.dropboxapi.com/2';

export const DROPBOX_GET_THUMBNAIL = 'https://content.dropboxapi.com/2/files/get_thumbnail_v2';

export const SILENT_ERROR_URLS = [DROPBOX_GET_THUMBNAIL];

export const SORT_DIRECTION_ASC = 'asc';

export const SORT_DIRECTION_DESC = 'desc';

export const ACTION_ICONS = [
  { action: 'estudio', icon: 'icon--studio' },
  { action: 'teléfono', icon: 'icon--phone' },
  { action: 'email/mensaje', icon: 'icon--envelope' },
  { action: 'visita', icon: 'icon--visit' },
  { action: 'máquina cl', icon: 'icon--machine' },
  { action: 'lab', icon: 'icon--lab' },
  { action: 'proveedor', icon: 'icon--supplier' },
];

export const NOTIFICATION_OPTIONS = [
  { code: 'COMMERCIAL_ACTION', color: '#19C15B' },
  { code: 'COMMERCIAL_ACTION_REMINDER', color: '#FFA500' },
  { code: 'INTERNAL_ACTION', color: '#E445DE' },
  { code: 'INTERNAL_ACTION_REMINDER', color: '#FBA500' },
  { code: 'WORK_GROUP', color: '#0D3DC0' },
];

export const NOTIFICATION_TYPE_COMMERCIAL_ACTION = 'COMMERCIAL_ACTION';
export const NOTIFICATION_TYPE_COMMERCIAL_ACTION_REMINDER = 'COMMERCIAL_ACTION_REMINDER';
export const NOTIFICATION_TYPE_INTERNAL_ACTION = 'INTERNAL_ACTION';
export const NOTIFICATION_TYPE_INTERNAL_ACTION_REMINDER = 'INTERNAL_ACTION_REMINDER';
export const NOTIFICATION_TYPE_WORK_GROUP = 'WORK_GROUP';
export const NOTIFICATION_TYPE_PROJECT_WITHOUT_OFFER = 'PROJECT_WITHOUT_OFFER';
export const NOTIFICATION_TYPE_PROJECT_WITHOUT_SUCCESSFUL_TRIAL = 'PROJECT_WITHOUT_SUCCESSFUL_TRIAL';
export const NOTIFICATION_TYPE_PROJECT_WITHOUT_INVOICE = 'PROJECT_WITHOUT_INVOICE';
export const NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_TEMPLATE = 'PROJECT_PENDING_SUPPLIER_TEMPLATE';
export const NOTIFICATION_TYPE_PROJECT_PENDING_SUPPLIER_RESPONSE = 'PROJECT_PENDING_SUPPLIER_RESPONSE';

export const NOTIFICATIONS_WORK_GROUP_ICON = 'icon--users';

export const NOTIFICATIONS_REMINDER_ICON = 'icon--time';

export const NOTIFICATIONS_PROJECT_ICON = 'icon--dashboard';

export enum FileTypes {
  EXCEL = 'application/vnd.ms-excel',
}

export const MONTH_OPTIONS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const GRAPH_TYPE_LINE = 'line';

export const MONTH_NON_SELECTED_LABEL = 'NONE';

export const MONTH_NON_SELECTED_VALUE = -1;

export const YEAR_NON_SELECTED_LABEL = 'NONE';

export const YEAR_NON_SELECTED_VALUE = -1;

// There is only budgets data from May 2023

export const REPORTS_BUDGETS_MIN_YEAR = 2023;

export const REPORTS_BUDGETS_MIN_MONTH = 4;

export const REPORTS_GRAPHS_MIN_YEAR = 2013;

export const REPORTS_GRAPHS_MIN_MONTH = 1;

export const REPORTS_GRAPHS_DEFAULT_NUMBER_OF_CUSTOMERS = 10;

// GRAPHS

export const GRAPH_BAR_DATASET_2_PERCENTAGE = 0.4;

export const GRAPH_HOVER_BACKGROUND_COLOR = 'grey';
export const GRAPH_HOVER_BORDER_COLOR = '#FFFFFF';

export const GRAPH_BAR_DATASET_1_BACKGROUND_COLOR = '#FEDD33';
export const GRAPH_BAR_DATASET_2_BACKGROUND_COLOR = '#484549';
export const GRAPH_BAR_DATASET_3_BACKGROUND_COLOR = '#9C27B0';
export const GRAPH_BAR_DATASET_4_BACKGROUND_COLOR = '#E91E63';

export const COLORS_BY_INDEX = [
  GRAPH_BAR_DATASET_1_BACKGROUND_COLOR,
  GRAPH_BAR_DATASET_2_BACKGROUND_COLOR,
  GRAPH_BAR_DATASET_3_BACKGROUND_COLOR,
  GRAPH_BAR_DATASET_4_BACKGROUND_COLOR,
];

export const GRAPH_PIE_BACKGROUND_COLOR_LIST = [
  '#FEDD33',
  '#937F00',
  '#3E2B00',
  '#484549',
  '#8D8A8E',
  '#B3AFB4',
  '#FEDD33',
  '#937F00',
  '#3E2B00',
  '#484549',
  '#8D8A8E',
  '#B3AFB4'
];

export const GRAPH_SUPPLIER_COLOR_LIST = [
  '#33cccc',
  '#2E7DBD',
  '#15216A',
  '#969696',
  '#FEDD33',
  '#4E4E4C',
  '#33cccc',
  '#2E7DBD',
  '#15216A',
  '#969696',
  '#FEDD33',
  '#4E4E4C',
];

export const GRAPH_PROVINCES_COLOR_LIST = [
  '#FE3333',
  '#33F3FE',
  '#5ADB8A',
  '#FEDD33',
  '#3366FE',
  '#FE7833',
];


export const GRAPH_PLUGIN_TOOLTIP_DEFAULTS: ChartConfiguration['options']['plugins']['tooltip'] = {
  caretSize: 10,
  displayColors: false,
};

export const GRAPH_LEGEND_FONT_COLOR = '#484549';

export const GRAPH_LEGEND_FONT_SIZE = 10;

export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const REPORTS_OTHERS_LABEL = '__others__';

export const REPORTS_FROM_YEAR_START_INDICATOR = '** ';

export const REPORTS_GENERAL_NUMBER_OF_YEARS = 6;

export const REPORTS_GENERAL_BILLING_BY_FAMILY_NUMBER_OF_YEARS = 10;

export const ALLOWED_EXTENSION_FOR_GENERATING_REPORTS = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'heic'];

export const CUSTOMER_REPORT_MODE_SELECTOR_OPTIONS: OptionModeInterface[] = [
  { name: 'sales', value: 1, translation: 'reports.customerReports.modeSelector.sales' },
  { name: 'billing', value: 2, translation: 'reports.customerReports.modeSelector.billing' },
];

export const TOLERANCE_POSITION_OPTIONS: OptionInterface[] = [
  { label: '+', value: '+' },
  { label: '-', value: '-' }
];

export const PROJECT_STATUS_ANALYSIS = 'analysis';
export const PROJECT_STATUS_START = 'start';
export const PROJECT_STATUS_FINISH = 'finish';
export const PROJECT_STATUS_STARTUP = 'startup';

export const ROUTES = {
  COMMERCIAL_ACTIONS: '/actions/commercial-actions',
  INTERNAL_ACTIONS: '/actions/internal-actions',
  PROJECT_ANALYSIS_OFFER: (hash: string) => `/projects/${hash}/analysis/offer`,
  PROJECT_WITHOUT_SUCCESSFUL_TRIAL: (hash: string, status: string) => `/projects/${hash}/${status}/trial`,
  PROJECT_INVOICES: (hash: string, status: string) => `/projects/${hash}/${status}/invoices`,
  PROJECT_PENDING_SUPPLIER_ACTIONS: (hash: string, status: string) => `/projects/${hash}/${status}/supplier-action`,

  ADMIN_APPLICATIONS: '/admin/applications',
  ADMIN_COMPETITORS: '/admin/competitors',
  ADMIN_HARDNESSES: '/admin/hardnesses',
  ADMIN_MACHINES: '/admin/machines',
  ADMIN_MATERIALS: '/admin/materials',
  ADMIN_PIECE_TYPE: '/admin/piecetypes',
  ADMIN_PRE_MACHINED_METHODS: '/admin/pre-machine-methods',
  ADMIN_PRIVILEGES: '/admin/privileges',
  ADMIN_PROCESSES: '/admin/processes',
  ADMIN_PROJECT_SINKING_REASONS: '/admin/project-sinking-reasons',
  ADMIN_SPECIFICATIONS: '/admin/specifications',
  ADMIN_TRIAL_OBJECTIVES: '/admin/trial-objectives',
  ADMIN_USERS: '/admin/users',
};

export const PROCESS_TYPE_INTERNAL = '1';
export const PROCESS_TYPE_EXTERNAL = '2';

export const VALID_FORM = 'VALID';

export const OTHER_OPTION_VALUE = 'OTHER';

export const END_PROJECT_URL_SUFFIX = 'end';

export const DONE_NOTIFICATIONS_FILTER = 'done';

export const PENDING_NOTIFICATIONS_FILTER = 'pending';

export const DOC_FILE_TYPE = 'doc';

export const PDF_FILE_TYPE = 'pdf';

export const LANGS = [
  { value: 'es', label: 'langs.es' },
  { value: 'en', label: 'langs.en' },
];

export const DEFAULT_LANG = first(LANGS);

export const REPORT_RANKING_NUMBER_OF_RESULTS_MAX = 20;

export enum ForecastType {
  TURNOVER = 'turnover',
  PURCHASES = 'purchases',
  EMPLOYMENT = 'employment',
  EMPLOYMENT_BASE_SALARY = 'employment_base_salary',
  EMPLOYMENT_COMMISSIONS = 'employment_commissions',
  AMORTIZATION = 'amortization',
  GENERAL = 'general',
  LAB = 'lab',
  MARKETING = 'marketing',
  MARKETING_SPONSORSHIPS = 'marketing_sponsorships',
  MARKETING_EXHIBITIONS = 'marketing_exhibitions',
  MARKETING_ADVERTISING = 'marketing_advertising',
}

export enum ExpenseType {
  EMPLOYMENT = 'employment',
  EMPLOYMENT_BASE_SALARY = 'employment_base_salary',
  EMPLOYMENT_COMMISSIONS = 'employment_commissions',
  AMORTIZATION = 'amortization',
  GENERAL = 'general',
  LAB = 'lab',
  MARKETING = 'marketing',
  MARKETING_SPONSORSHIPS = 'marketing_sponsorships',
  MARKETING_EXHIBITIONS = 'marketing_exhibitions',
  MARKETING_ADVERTISING = 'marketing_advertising',
}

export enum ComplianceType {
  TURNOVER = 'billing',
  PURCHASES = 'purchases',
  EMPLOYMENT = 'employment',
  EMPLOYMENT_BASE_SALARY = 'employment_base_salary',
  EMPLOYMENT_COMMISSIONS = 'employment_commissions',
  AMORTIZATION = 'amortization',
  GENERAL = 'general',
  LAB = 'lab',
  MARKETING = 'marketing',
  MARKETING_SPONSORSHIPS = 'marketing_sponsorships',
  MARKETING_EXHIBITIONS = 'marketing_exhibitions',
  MARKETING_ADVERTISING = 'marketing_advertising',
}

export const BUDGET_DASHBOARD_PREVIOUS_YEAR_RANGE = 10;

export const GRAPH_BAR_DATASET_EXPENSES_COLORS = [
  '#FFD700',
  '#2196F3',
  '#4CAF50',
];

export const GRAPH_BAR_DATASET_FORECASTS_COLORS = [
  '#FFECB3',
  '#BBDEFB',
  '#C8E6C9',
];

// Weekly report

export const WEEKLY_REPORT_NO_DATA_STRING = '---';
