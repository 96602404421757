import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ERP_CONNECTION_ERROR_CODE, SILENT_ERROR_URLS } from '../../../config/constants';
import { ValidationService } from '../../validation/validation.service';
import { HEADER_AVOID_ERROR_HANDLING, HEADER_ERROR_HANDLING, HEADER_ERROR_PREFIX } from '../headers';
import { ToasterService } from '../../visual/toaster.service';
import { TranslateService } from '@ngx-translate/core';

const isUpdatingRequest = (method: string) => method === 'POST' || method === 'PUT' || method === 'DELETE';

const areResponseErrorsToBeHandled = (req: HttpRequest<any>) => {
  return (isUpdatingRequest(req.method))
    ? !req.headers.has(HEADER_AVOID_ERROR_HANDLING)
    : req.headers.has(HEADER_ERROR_HANDLING);
};

const getErrorPrefixFromHeaders = (headers: HttpHeaders) => {
  return headers.get(HEADER_ERROR_PREFIX) || null;
};

const decodeArrayBufferError = (error: ArrayBuffer) => JSON.parse(JSON.stringify(error));

const parseError = (err: { [key: string]: string } | ArrayBuffer) => (err instanceof ArrayBuffer) ? decodeArrayBufferError(err) : err;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private validationService: ValidationService;
  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private inj: Injector
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadValidationService();
    return next.handle(req)
      .pipe(
        catchError((err: any) => {
          if (SILENT_ERROR_URLS.includes(req.url)) {
            return;
          }

          if (err.status === ERP_CONNECTION_ERROR_CODE) {
            this.toasterService.showErpConnectionError(this.translateService.instant('error.erpConnectionError'));
          }

          if (err instanceof HttpErrorResponse && areResponseErrorsToBeHandled(req)) {
            const error = parseError(err.error);
            const errorPrefix = getErrorPrefixFromHeaders(req.headers);
            return this.validationService.manageHttpResponseErrors(error, errorPrefix);
          }

          return _throw(err);
        })
      );
  }

  private loadValidationService() {
    if (!this.validationService) {
      this.validationService = this.inj.get(ValidationService);
    }
  }
}
