import { ReportSerie } from 'src/app/core/models/report-serie';
import { mapCollection } from 'src/app/core/utils/collection.utils';

export class ReportSerieBuilder {
  static fromJson(json: any): ReportSerie {
    return (json)
      ? new ReportSerie(
        json.name,
        json.values
      )
      : null;
  }

  static fromList(list: any[]): ReportSerie[] {
    return Array.isArray(list)
      ? mapCollection(ReportSerieBuilder.fromJson, list)
      : [];
  }

  static mergeCompareDataSets(
    year: number,
    firstResult: ReportSerie[],
    compareYear: number,
    secondResult: ReportSerie[],
    overwriteSerieName: string = null,
    type: string = null
  ): ReportSerie[] | [] {


    const modFirstResult: ReportSerie[] = firstResult.map(
      (serie: ReportSerie) => new ReportSerie(`${year} - ${overwriteSerieName ?? serie.name}`, serie.values, type)
    );

    const modSecondResult: ReportSerie[] = secondResult.map(
      (serie: ReportSerie) => new ReportSerie(`${compareYear} - ${overwriteSerieName ?? serie.name}`, serie.values, type)
    );

    return modFirstResult.concat(modSecondResult);
  }

  static getEmptyReportSerie() {
    return new ReportSerie(
      '',
      []
    );
  }
}
