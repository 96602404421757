export class ReportSerie {
  constructor(
    public name?: string,
    public values?: number[],
    public defaultType?: string,
    public stackId?: string,
  ) { }

  clone(): ReportSerie {
    return new ReportSerie(
      this.name,
      this.values,
      this.defaultType,
      this.stackId,
    );
  }
}
