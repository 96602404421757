import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ProjectBuilder } from '../../models/builder/project.builder';
import { Project } from '@/core/models/project';
import { ProjectInvoice } from '@/core/models/project-invoice';
import { ProjectDeliveryNote } from '@/core/models/project-delivery-note';
import { ProjectProfitabilityBuilder } from '@/core/models/builder/project-profitability.builder';
import { ProjectProfitability } from '../../models/project-profitability';

const createProjectBody = (project: Project, projectGroupHash) => ({
  customerId: project.customer,
  commercials: project.commercials,
  contacts: project.contacts,
  application: project.application,
  status: project.status,
  dropboxFolderId: project.dropboxFolderId,
  pieceName: project.pieceName,
  pieceTypeHash: project.pieceType,
  processHash: project.process,
  processType: project.processType,
  planeNumber: project.planeNumber,
  sectorId: project.sector,
  supplierHash: project.supplier,
  projectGroupHash
});

const copyProjectBody = (project: Project) => ({
  application: project.application,
  status: project.status,
  dropboxFolderId: project.dropboxFolderId,
  supplierHash: project.supplier,
  processHash: project.process,
  processType: project.processType,
  projectGroupHash: project.projectGroupHash
});

const updateProjectStatusBody = (project: Project) => ({
  status: project.status,
  dropboxFolderId: project.dropboxFolderId,
  endStatus: project.endStatus,
  sinkingReasonHash: project.sinkingReason?.hash,
  endReason: project.endReason,
  startupMonths: project.startupMonths,
});

const updateProjectBody = (project: Project) => ({
  application: project.application,
  supplierHash: project.supplier,
  processHash: project.process,
  processType: project.processType,
});

const updateProjectErpOfferBody = (project: Project) => ({
  erpOfferNumber: project.erpOfferNumber,
  erpOfferRevisionNumber: project.erpOfferRevisionNumber,
});

const updateProjectProjectDeliveryNotesBody = (project: Project) => ({
  deliveryNoteNumbers: project.deliveryNotes.map((deliveryNote: ProjectDeliveryNote) => deliveryNote.deliveryNoteNumber),
});

const updateProjectProjectInvoicesBody = (project: Project) => ({
  invoiceNumbers: project.invoices.map((invoice: ProjectInvoice) => invoice.invoiceNumber),
});

@Injectable()
export class ProjectApiService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}projects/`;
  }

  getProject(hash: string) {
    return this.httpClient.get(`${this.apiUrl}${hash}`)
      .pipe(map(ProjectBuilder.fromJson));
  }

  getProjectProfitability(hash: string): Observable<ProjectProfitability> {
    return this.httpClient.get(`${this.apiUrl}${hash}/profitability`)
      .pipe(map(ProjectProfitabilityBuilder.fromJson));
  }

  // FIXME: This endpoint is deprecated, always returns an error (detected in IRA-958)
  getProjects() {
    return this.httpClient.get(this.apiUrl)
      .pipe(map(ProjectBuilder.fromList));
  }

  getProjectsByCustomer(customerId: number) {
    let params = new HttpParams();
    params = params.set('customer', customerId);
    return this.httpClient.get(this.apiUrl, { params })
      .pipe(map(ProjectBuilder.fromList));
  }

  getFilteredProjects(fromDate: string, toDate: string, status: string, customerId: number, commercialId?: number): Observable<Project[]> {
    let params = new HttpParams();
    if (fromDate) { params = params.set('fromDate', fromDate); }
    if (toDate) { params = params.set('toDate', toDate); }
    if (status) { params = params.set('status', status); }
    if (customerId) { params = params.set('customer', customerId.toString()); }
    if (commercialId) { params = params.set('commercial', commercialId.toString()); }

    return this.httpClient.get(this.apiUrl, { params })
      .pipe(
        map(ProjectBuilder.fromList)
      );
  }

  createProject(project: Project, projectGroupHash: string) {
    const postBody = new FormData();
    postBody.append('project', JSON.stringify(createProjectBody(project, projectGroupHash)));

    return this.httpClient.post(this.apiUrl, postBody)
      .pipe(
        map(ProjectBuilder.fromJson)
      );
  }

  copyProject(project: Project) {
    const postBody = new FormData();
    postBody.append('project', JSON.stringify(copyProjectBody(project)));

    return this.httpClient.post(`${this.apiUrl}copy`, postBody)
      .pipe(
        map(ProjectBuilder.fromJson)
      );
  }

  updateProject(project: Project) {
    const postBody = new FormData();
    postBody.append('project', JSON.stringify(updateProjectBody(project)));

    return this.httpClient.post(`${this.apiUrl}${project.hash}`, postBody)
      .pipe(
        map(ProjectBuilder.fromJson)
      );
  }

  updateProjectStatus(project: Project) {
    return this.httpClient.put(`${this.apiUrl}${project.hash}/status`, updateProjectStatusBody(project));
  }

  updateErpOffer(project: Project) {
    return this.httpClient.put(`${this.apiUrl}${project.hash}/erp-offer`, updateProjectErpOfferBody(project));
  }

  updateDeliveryNotes(project: Project) {
    return this.httpClient.put(`${this.apiUrl}${project.hash}/delivery-notes`, updateProjectProjectDeliveryNotesBody(project));
  }

  updateInvoices(project: Project) {
    return this.httpClient.put(`${this.apiUrl}${project.hash}/invoices`, updateProjectProjectInvoicesBody(project));
  }

  updatePendingSupplierTemplateStatus(projactHash: string, pendingSupplierTemplate: boolean): Observable<Project> {
    return this.httpClient.put(`${this.apiUrl}${projactHash}/pending-supplier-template`, { pendingSupplierTemplate })
      .pipe(
        map(ProjectBuilder.fromJson)
      );
  }

  updatePendingSupplierResponseStatus(projactHash: string, pendingSupplierResponse: boolean): Observable<Project> {
    return this.httpClient.put(`${this.apiUrl}${projactHash}/pending-supplier-response`, { pendingSupplierResponse })
      .pipe(
        map(ProjectBuilder.fromJson)
      );
  }

  deleteProject(hash: string) {
    return this.httpClient.delete(`${this.apiUrl}${hash}`);
  }

  getProjectCount() {
    return this.httpClient.get(`${this.apiUrl}count`);
  }
}
