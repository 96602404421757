export class ReportSerie {
  constructor(
    public name?: string,
    public values?: number[],
    public defaultType?: string,
  ) { }

  clone(): ReportSerie {
    return new ReportSerie(
      this.name,
      this.values,
      this.defaultType
    );
  }
}
